
$('#mostPerfumes').slick({
	autoplay: false,
	infinite: false,
	slidesToShow: 7,
	slidesToScroll: 1,
	arrows: true,
	prevArrow: '<button type="button" class="slick-prev slick-arrow" style="display: inline-block;"></button>',
	nextArrow: '<button type="button" class="slick-next slick-arrow" style="display: inline-block;"></button>',
	responsive: [
        {
			breakpoint: 1200,
			settings: {
				slidesToShow: 5,
				slidesToScroll: 1,
			}
		},
        {
			breakpoint: 992,
			settings: {
				slidesToShow: 4,
				slidesToScroll: 1,
			}
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
			}
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			}
		},
	]
});