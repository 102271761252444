
$(document).ready(function() {

    if($( ".date-filter input" ).length)
        $( ".date-filter input" ).datepicker();

	if($('.history-list.owl-carousel').length) {
	    $('.history-list').owlCarousel({
	        items: 3,
	        responsive: {
	            0 : {
	                items: 1
	            },
	            768 : {
	                items: 2
	            },
	            1200 : {
	                items: 3
	            }
	        },
	        margin: 15,
	        dots: false,
	        nav: true,
	        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"]
	    });
	}
    $(document)
        .on('click', '.date-filter span', function() {
            $( ".date-filter input" ).datepicker("show");
        })
        .on('click', '.result-filter span', function() {
            $( ".result-filter .drop-pop" ).fadeToggle('fast');
        })
        .on('click', '.search-result ul li', function() {
            var me = $(this);
            if(me.hasClass('active')) {
                $(this).removeClass('active');

             	var perfum_id=$(this).attr('data-id');

             	$(".perfum"+perfum_id).remove();

				$('.products-slider >div[data-index='+me.index()+']').remove();
            } else {
                $(this).addClass('active');
                var perfum_id=$(this).attr('data-id');
                var item = $(".chosen-products");
				item.append('<input type="hidden" class="perfum'+perfum_id+'" name="chosen[]" value="'+perfum_id+'" />');

				var clone = $('.products-slider > div:first').clone();
				clone.removeClass('hide');
				clone.find('img').attr('src', me.find('img').attr('src'));
				clone.find('p').html(me.find('span').html());
				clone.attr('data-index', me.index());
				$('.products-slider').append(clone);
            }
        })
        .on('click', '.chosen-products .products-slider .remove', function() {
            var product = $(this).parent();
            product.fadeOut(function() {
                product.remove();
            });
			$('.search-result ul li').eq(product.data('index')).removeClass('active');
        })
        .on('click', '.add-social .btn', function(e) {
            e.preventDefault();
            var clone = $('.social-list .social-link:first').clone();
            clone.find('input').attr('name', 'socail['+$('.add-social select').val()+'][]').val("");
			clone.removeClass('hide').addClass($('.add-social select').val());
            $('.social-list').append(clone);
        })
        .on('click', '.social-list .remove', function(e) {
            e.preventDefault();
            var me = $(this);
            // if($('.social-list .social-link').length > 1)
                me.parent().remove();
        })
        .on("change", "#profile-photo", function() {
    		var file = this.files[0];
    		if(isImage(file.name)) {
    			preview(this, $('.image-preview'));
    		}
    	})
		.on("click", ".story .read-more span", function() {
			var me = $(this);
			if(me.find('i').hasClass('fa-angle-up'))
				me.find('i').removeClass('fa-angle-up').addClass('fa-angle-down');
			else
				me.find('i').addClass('fa-angle-up').removeClass('fa-angle-down');
			$(this).parent().parent().toggleClass('active');
		})

		.on("change", ".country-select", function() {
			var me = $(this);
			var code_phone = $('option:selected', this).data('code-phone');
			// $('.code-phone').text(code_phone);
			$(".form-group.phone").addClass('active');
			$('.form-group.phone > input[type=hidden]').val(code_phone);
			$(".form-group.phone > span").text(code_phone);
		})
		.on("keypress", ".phone .form-control", function(event) {
			validateNumber(event);
		})
});

function preview(input, selector) {
	if (input.files && input.files[0]) {
		var reader = new FileReader();

		reader.onload = function(e) {
			if(selector.find('img').length)
				selector.find('img').attr('src', e.target.result);
			else
				selector.html('<img src="'+e.target.result+'" />').show();
		};
		reader.readAsDataURL(input.files[0]);
	}
}
function isImage(filename) {
	var ext = (/[^.]+$/.exec(filename));
	switch (ext[0].toLowerCase()) {
	case 'jpg':
	case 'gif':
	case 'bmp':
	case 'png':
		return true;
	}
	return false;
}
function validateNumber(evt) {
  var theEvent = evt || window.event;
  var key = theEvent.keyCode || theEvent.which;
  key = String.fromCharCode( key );
  var regex = /[0-9]|\./;
  if( !regex.test(key) ) {
    theEvent.returnValue = false;
    if(theEvent.preventDefault) theEvent.preventDefault();
  }
}
