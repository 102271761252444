$(".scrollbar--inner").mCustomScrollbar({
    live:true,
    theme:"inset-dark",
});


// show search section
$('.perfume-inputSearch input').on('click' , function() {
    // $(this).parentsUntil('.perfume-inputSearch').parent().find('.afterSearch-sec').show();
})


$(".perfume-inputSearch input").blur(function(){
    // $(this).parentsUntil('.perfume-inputSearch').parent().find('.afterSearch-sec').hide();
});

$('#dropdownElementLink a').on('click',function(){
    dropButton = $(this).parentsUntil('.dropdown').parent().find('#dropdownButton');
    src = $(this).find('img').attr('src');
    dropButton.find('img').attr('src' , src);
    if ($(this).hasClass('goodLink')) {
        dropButton.removeClass('backgroundRed backgroundGray')
        dropButton.addClass('backgroundGreen');
    }
    else if ($(this).hasClass('badLink')){
        dropButton.removeClass('backgroundGreen backgroundGray')
        dropButton.addClass('backgroundRed');
    }
    else{
        dropButton.removeClass('backgroundGreen backgroundRed')
        dropButton.addClass('backgroundGray');
    }
} )


// Check if body height is higher than window height :)
if ($("body").height() > $(window).height()) {
}
else{
    $('.copyrightsFooter').addClass('absoluteClass');
}

// show filter 

$('#showFilter').on('click' , function() {
    $('#filterForm').toggle('fade');
})


// on search auto complete and show search

// function autocomplete(inp, arr) {
//     /*the autocomplete function takes two arguments,
//      the text field element and an array of possible autocompleted values:*/
//     var currentFocus;
//     /*execute a function when someone writes in the text field:*/
//     inp.addEventListener("input", function(e) {
//         var a_div, b_Li, i, val = this.value;
//         /*close any already open lists of autocompleted values*/
//         closeAllLists();
//         if (!val) { return false;}
//         currentFocus = -1;
//
//         /*create a DIV element that will contain the items (values):*/
//         a_div = document.createElement("div");
//         a_div.setAttribute("id", this.id + "autocomplete-list");
//         a_div.setAttribute("class", "autocomplete-items afterSearch-sec");
//         /*append the DIV element as a child of the autocomplete container:*/
//         this.parentNode.appendChild(a_div);
//
//         /*for each item in the array...*/
//         for (i = 0; i < arr.length; i++) {
//             /*check if the item starts with the same letters as the text field value:*/
//             if (arr[i].name.substr(0, val.length).toUpperCase() == val.toUpperCase()) {
//                 /*create a DIV element for each matching element:*/
//                 b_Li = document.createElement("li");
//                 /*insert img*/
//                 b_Li.innerHTML = "<img src='../new_website/images/add.svg' class='addButton' "+ "'>";
//                 //add perfume img
//                 b_Li.innerHTML += "<img src='" + arr[i].icon + "'class='afterSearchimg' "+ "'>";
//                 /*make the matching letters bold:*/
//                 b_Li.innerHTML += "<strong>" + arr[i].name.substr(0, val.length) + "</strong>";
//                 b_Li.innerHTML += arr[i].name.substr(val.length);
//                 /*insert a input field that will hold the current array item's value:*/
//                 b_Li.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
//
//                 /*execute a function when someone clicks on the item value (DIV element):*/
//                 b_Li.addEventListener("click", function(e) {
//                     /*insert the value for the autocomplete text field:*/
//                     inp.value = this.document.getElementsByTagName("input")[0].value;
//                     /*close the list of autocompleted values,
//                      (or any other open lists of autocompleted values:*/
//                     closeAllLists();
//                 });
//
//                 a_div.appendChild(b_Li);
//
//             }
//         }
//         org_html = a_div.innerHTML;
//         new_html = "<div class='scrollbar--inner'>" + "<ul>" + org_html +" </ul>" + "</div> <button class='btn mt-3 mb-3 doneButton'>Done</button>";
//         a_div.innerHTML = new_html;
//         $(".scrollbar--inner").mCustomScrollbar({
//             live:true,
//             theme:"inset-dark",
//         });
//     });
//
//
//
//     /*execute a function presses a key on the keyboard:*/
//     inp.addEventListener("keydown", function(e) {
//         var x = document.getElementById(this.id + "autocomplete-list");
//         if (x) x = x.getElementsByTagName("div");
//         if (e.keyCode == 40) {
//             /*If the arrow DOWN key is pressed,
//              increase the currentFocus variable:*/
//             currentFocus++;
//             /*and and make the current item more visible:*/
//             addActive(x);
//         } else if (e.keyCode == 38) { //up
//             /*If the arrow UP key is pressed,
//              decrease the currentFocus variable:*/
//             currentFocus--;
//             /*and and make the current item more visible:*/
//             addActive(x);
//         } else if (e.keyCode == 13) {
//             /*If the ENTER key is pressed, prevent the form from being submitted,*/
//             e.preventDefault();
//             if (currentFocus > -1) {
//                 /*and simulate a click on the "active" item:*/
//                 if (x) x[currentFocus].click();
//             }
//         }
//     });
//
//
//     function addActive(x) {
//         /*a function to classify an item as "active":*/
//         if (!x) return false;
//         /*start by removing the "active" class on all items:*/
//         removeActive(x);
//         if (currentFocus >= x.length) currentFocus = 0;
//         if (currentFocus < 0) currentFocus = (x.length - 1);
//         /*add class "autocomplete-active":*/
//         x[currentFocus].classList.add("autocomplete-active");
//     }
//     function removeActive(x) {
//         /*a function to remove the "active" class from all autocomplete items:*/
//         for (var i = 0; i < x.length; i++) {
//             x[i].classList.remove("autocomplete-active");
//         }
//     }
//     function closeAllLists(elmnt) {
//         /*close all autocomplete lists in the document,
//          except the one passed as an argument:*/
//         var x = document.getElementsByClassName("autocomplete-items");
//         for (var i = 0; i < x.length; i++) {
//             if (elmnt != x[i] && elmnt != inp) {
//                 x[i].parentNode.removeChild(x[i]);
//             }
//         }
//     }
//     /*execute a function when someone clicks in the document:*/
//     document.addEventListener("click", function (e) {
//         closeAllLists(e.target);
//     });
// }


function toggleElementInPersona(url, item, status, keywords, notes) {

    $.ajax({
        headers: {'X-CSRF-Token': $('meta[name=_token]').attr('content')},
        url: url,
        type: 'POST',
        // cache: true,
        data: {
            'status': status,
            'item' : item,
            'keywords' : keywords,
            'notes' : notes,
            'ajax': true,
            '_token': personaConfig.sessionToken
        },

        success: function (data) {

        },
    });
}
function getPerfumeInfo(id) {
    event.preventDefault();
    $.ajax({
        headers: {'X-CSRF-Token': $('meta[name=_token]').attr('content')},
        url: perfumeInfoConfig.url,
        type: 'GET',
        contentType: "application/x-www-form-urlencoded; charset=utf-8",
        data: {'id': id}, //see the $_token

        success: function (data) {

            $('#perfumeStory').html( data['story'].substr(1, 90)+' ...');

            $('#perfumeTitle').html(data['title']);

            $('#perfumeSex').html(data['sex']);

            $('#perfumeBrand').html(data['brand']);

            if (data['topNote'] !== '' && typeof(data['topNote']) !== 'undefined') {
                $('#topNote').html(data['topNote']);
                $('#topNoteDiv').show();
            }

            if (data['heartNote'] !== '' && typeof(data['heartNote']) !== 'undefined') {
                $('#heartNote').html(data['heartNote']);
                $('#heartNoteDiv').show();
            }

            if (data['baseNote'] !== '' && typeof(data['baseNote']) !== 'undefined')
            {
                $('#baseNote').html(data['baseNote']);
                $('#baseNoteDiv').show();
            }

            if (typeof(data['mainNote']) !== 'undefined') {
                $('#mainNote').html(data['mainNote']);
                $('#mainNoteDiv').show();
            }

            $("#perfumeImg").attr("src", data['imgUrl']);

            $('.viewBtn').attr('id', id);

            $('#perfumeInfoModal').modal('show');

        },
    });
}

// add selcted element after search
var evenCheck = 0;
$(document).on('click', '#persona1Inputautocomplete-list li' , function() {
    imgUrl = ($(this)[0].childNodes[1].src) ;
    perfumeID = $(this)[0].childNodes[3].value;
    keywords = $(this)[0].childNodes[4].value;
    notes = $(this)[0].childNodes[5].value;

    firstColLength = $('.persona1-itemSelected .col-lg-6:first-child *').length ;
    lastColLength = $('.persona1-itemSelected .col-lg-6:last-child *').length ;
    let selectedItem  =  `
        <div class="itemSelected">
            <img src="${imgUrl}" alt="perfume image" class="itemSelected-img">
            <h3>${$(this).text()}</h3>
            <a href="#" class="btn rounded " id="${perfumeID}" onclick="getPerfumeInfo(this.id)"><i class="fas fa-info"></i></a>
            <button class="btn removeElement" id="${perfumeID}"> <i class="fas fa-times"></i> </button>
            <input type='hidden' name='chosen[]' value="${perfumeID}" form="stepOne">
            <input type='hidden' name='keywords[]' id="keywords${perfumeID}" value="${keywords}" form="stepOne">
            <input type='hidden' name='notes[]' id="notes${perfumeID}" value="${notes}" form="stepOne">
        </div> <!--end selected item-->
    `;
    if (firstColLength == 0 && lastColLength == 0) {
        if(evenCheck % 2 == 0) {
            $(selectedItem).appendTo('.persona1-itemSelected .col-lg-6:first-child');
        }
        else{
            $(selectedItem).appendTo('.persona1-itemSelected .col-lg-6:last-child');
        }
    }
    else if ( firstColLength > lastColLength) {
        $(selectedItem).appendTo('.persona1-itemSelected .col-lg-6:last-child');
    }
    else{
        $(selectedItem).appendTo('.persona1-itemSelected .col-lg-6:first-child');
    }
    toggleElementInPersona(personaConfig.url, perfumeID, 1, keywords, notes)

    //remove element
    $('.removeElement').on('click' , function() {
        toggleElementInPersona(personaConfig.url, perfumeID, 0,  keywords, notes);
        $(this).parent().remove();
    });
    evenCheck++ ;


});


/**
 ************
 ***********
 ********
 ******
 *****
 Second auto complete
 */

// on search auto complete and show search
// on search auto complete and show search
function autocomplete_4(inp, arr) {
    /*the autocomplete function takes two arguments,
     the text field element and an array of possible autocompleted values:*/
    var currentFocus;
    /*execute a function when someone writes in the text field:*/
    inp.addEventListener("input", function(e) {
        var a_div, b_Li, i, val = this.value;
        /*close any already open lists of autocompleted values*/
        closeAllLists();
        if (!val) { return false;}
        currentFocus = -1;

        /*create a DIV element that will contain the items (values):*/
        a_div = document.createElement("div");
        a_div.setAttribute("id", this.id + "autocomplete-list");
        a_div.setAttribute("class", "autocomplete-items afterSearch-sec afterSearch-sec-2");
        /*append the DIV element as a child of the autocomplete container:*/
        this.parentNode.appendChild(a_div);

        /*for each item in the array...*/


        for (i = 0; i < arr.length; i++) {


            /*check if the item starts with the same letters as the text field value:*/
            if (arr[i].name.substr(0, val.length).toUpperCase() == val.toUpperCase()) {
                /*create a DIV element for each matching element:*/
                b_Li = document.createElement("li");
                /*insert span + */
                b_Li.innerHTML += "<span> + </span>";
                /*make the matching letters bold:*/
                //b_Li.innerHTML += "<p>" + arr[i].name.substr(0, val.length) + "</p>";

                b_Li.innerHTML += "<h3> <strong>" + arr[i].name.substr(0, val.length) +"</strong>" +  arr[i].name.substr(val.length) +"</h3>";

                //b_Li.innerHTML += arr[i].name.substr(val.length);
                /*insert a input field that will hold the current array item's value:*/
                b_Li.innerHTML += "<input type='hidden' name='id' value='" + arr[i].id + "'>";

                /*execute a function when someone clicks on the item value (DIV element):*/
                b_Li.addEventListener("click", function(e) {
                    /*insert the value for the autocomplete text field:*/
                    inp.value = this.getElementsByTagName("input")[0].value;
                    /*close the list of autocompleted values,
                     (or any other open lists of autocompleted values:*/
                    closeAllLists();
                });

                a_div.appendChild(b_Li);
            }
        }
        org_html = a_div.innerHTML;
        new_html = "<div class='scrollbar--inner'>" + "<ul>" + org_html +" </ul>" + "</div> <button class='btn mt-3 mb-3 doneButton'>Done</button>";
        a_div.innerHTML = new_html;
        $(".scrollbar--inner").mCustomScrollbar({
            live:true,
            theme:"inset-dark",
        });
    });


    /*execute a function presses a key on the keyboard:*/
    inp.addEventListener("keydown", function(e) {
        var x = document.getElementById(this.id + "autocomplete-list");
        if (x) x = x.getElementsByTagName("div");
        if (e.keyCode == 40) {
            /*If the arrow DOWN key is pressed,
             increase the currentFocus variable:*/
            currentFocus++;
            /*and and make the current item more visible:*/
            addActive(x);
        } else if (e.keyCode == 38) { //up
            /*If the arrow UP key is pressed,
             decrease the currentFocus variable:*/
            currentFocus--;
            /*and and make the current item more visible:*/
            addActive(x);
        } else if (e.keyCode == 13) {
            /*If the ENTER key is pressed, prevent the form from being submitted,*/
            e.preventDefault();
            if (currentFocus > -1) {
                /*and simulate a click on the "active" item:*/
                if (x) x[currentFocus].click();
            }
        }
    });

    function addActive(x) {
        /*a function to classify an item as "active":*/
        if (!x) return false;
        /*start by removing the "active" class on all items:*/
        removeActive(x);
        if (currentFocus >= x.length) currentFocus = 0;
        if (currentFocus < 0) currentFocus = (x.length - 1);
        /*add class "autocomplete-active":*/
        x[currentFocus].classList.add("autocomplete-active");
    }
    function removeActive(x) {
        /*a function to remove the "active" class from all autocomplete items:*/
        for (var i = 0; i < x.length; i++) {
            x[i].classList.remove("autocomplete-active");
        }
    }
    function closeAllLists(elmnt) {
        /*close all autocomplete lists in the document,
         except the one passed as an argument:*/
        var x = document.getElementsByClassName("autocomplete-items");
        for (var i = 0; i < x.length; i++) {
            if (elmnt != x[i] && elmnt != inp) {
                x[i].parentNode.removeChild(x[i]);
            }
        }
    }
    /*execute a function when someone clicks in the document:*/
    document.addEventListener("click", function (e) {
        closeAllLists(e.target);
    });

}

function autocomplete_2(inp, arr) {

        var currentFocus;

        var a_div, b_Li, i, val = inp.value;

        closeAllLists();

        if (!val) { return false;}

        currentFocus = -1;

        a_div = document.createElement("div");
        a_div.setAttribute("id", inp.id + "autocomplete-list");
        a_div.setAttribute("class", "autocomplete-items afterSearch-sec afterSearch-sec-2");

        inp.parentNode.appendChild(a_div);

        /*for each item in the array...*/
        for (i = 0; i < arr.length; i++) {
            /*check if the item starts with the same letters as the text field value:*/
            if (arr[i].name.substr(0, val.length).toUpperCase() == val.toUpperCase()) {

                /*create a DIV element for each matching element:*/
                b_Li = document.createElement("li");

                /*insert span + */
                b_Li.innerHTML += "<span> + </span>";
                /*make the matching letters bold:*/

                b_Li.innerHTML += "<h3> <strong>" + arr[i].name.substr(0, val.length) +"</strong>" +  arr[i].name.substr(val.length) +"</h3>";

                /*insert a input field that will hold the current array item's value:*/
                b_Li.innerHTML += "<input type='hidden' name='id' value='" + arr[i].id + "'>";

                /*execute a function when someone clicks on the item value (DIV element):*/
                b_Li.addEventListener("click", function(e) {

                    /*insert the value for the autocomplete text field:*/
                    inp.value = this.getElementsByTagName("input")[0].value;

                    /*close the list of autocompleted values,
                     (or any other open lists of autocompleted values:*/
                    closeAllLists();
                });

                a_div.appendChild(b_Li);
            }
        }

        org_html = a_div.innerHTML;

        new_html = "<div class='scrollbar--inner'>" + "<ul>" + org_html +" </ul>" + "</div> <button class='btn mt-3 mb-3 doneButton'>Done</button>";

        a_div.innerHTML = new_html;

        $(".scrollbar--inner").mCustomScrollbar({
            live:true,
            theme:"inset-dark"
        });

        function addActive(x) {
            /*a function to classify an item as "active":*/
            if (!x) return false;
            /*start by removing the "active" class on all items:*/
            removeActive(x);
            if (currentFocus >= x.length) currentFocus = 0;
            if (currentFocus < 0) currentFocus = (x.length - 1);
            /*add class "autocomplete-active":*/
            x[currentFocus].classList.add("autocomplete-active");
        }
        function removeActive(x) {
            /*a function to remove the "active" class from all autocomplete items:*/
            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
            }
        }
        function closeAllLists(elmnt) {
            /*close all autocomplete lists in the document,
             except the one passed as an argument:*/
            var x = document.getElementsByClassName("autocomplete-items");
            for (var i = 0; i < x.length; i++) {
                if (elmnt != x[i] && elmnt != inp) {
                    x[i].parentNode.removeChild(x[i]);
                }
            }
        }

        /*execute a function when someone clicks in the document:*/
        document.addEventListener("click", function (e) {
            closeAllLists(e.target);
        });

}

// add selected element after search
$(document).on('click', '#persona2Inputautocomplete-list li' , function() {

    love_note = $(this)[0].childNodes[2].value;

    let selectedItem  = `
            <li class="selected">
                <a href="" onclick="javascript:event.preventDefault()">
                    <i class="fas fa-check"></i>
                    ${$(this).clone().find('span').remove().end().text()}
                    <input type="hidden" name="love_notes[]" form="stepTwo" value="${$(this)[0].childNodes[2].value}">
                </a>
            </li> `;

    $(selectedItem).appendTo('.persona2-itemSelected ul');

    toggleElementInPersona(personaConfig.url, love_note, 1);

    $('.selected').on('click' , function() {

        toggleElementInPersona(personaConfig.url, love_note, 0);

        $(this).remove();
    })

});

/**
 ************
 ***********
 ********
 ******
 *****
 third auto complete
 */

// add selcted element after search
$(document).on('click', '#persona3Inputautocomplete-list li' , function() {

    hate_note = $(this)[0].childNodes[2].value;

    let selectedItem  =  `
            <li class="selected">
             <a href="" onclick="javascript:event.preventDefault()">
                <i class="fas fa-check"></i>
                    ${$(this).clone().find('span').remove().end().text()}
                    <input type="hidden" name="hate_notes[]" form="stepThree" value="${$(this)[0].childNodes[2].value}">
                </a>
            </li>`;

    $(selectedItem).appendTo('.persona3-itemSelected ul');

    toggleElementInPersona(personaConfig.url, hate_note, 1);

    $('.selected').on('click' , function() {

        toggleElementInPersona(personaConfig.url, hate_note, 0);

        $(this).remove();
    })

});


/**
 ************
 ***********
 ********
 ******
 *****
 four - auto complete
 */
// add selcted element after search
$(document).on('click', '#persona4Inputautocomplete-list li' , function() {
    keyword = $(this)[0].childNodes[2].value;

    let selectedItem  =  `
            <li class="selected">
                <a href="" onclick="javascript:event.preventDefault()">
                    <i class="fas fa-check"></i>
                    ${$(this).clone().find('span').remove().end().text()}
                    <input type="hidden" name="keywords[]" form="stepFour" value="${$(this)[0].childNodes[2].value}">
                </a>
            </li>`;

    $(selectedItem).appendTo('.persona4-itemSelected ul');

    toggleElementInPersona(personaConfig.url, keyword, 1);

    $('.selected').on('click' , function() {

        toggleElementInPersona(personaConfig.url, keyword, 0);

        $(this).remove();
    })
})


// Most Popular for first page
var evenCheckPop = 0;

$('.persona1-most-popular .most-popular-item').on('click',function(){
    let removeBtn =  `<button class="btn removeElement"> <i class="fas fa-times"></i> </button>`;
    firstColLength = $('.persona1-itemSelected .col-lg-6:first-child *').length ;
    lastColLength = $('.persona1-itemSelected .col-lg-6:last-child *').length ;
    if (firstColLength == 0 & lastColLength == 0) {
        if(evenCheckPop % 2 == 0  ) {
            $(this).clone().find('.addButton').remove().end()
                .addClass('itemSelected').append(removeBtn)
                .appendTo('.persona1-itemSelected .col-lg-6:first-child');
        }
        else{
            $(this).clone().find('.addButton').remove().end().addClass('itemSelected')
                .append(removeBtn).appendTo('.persona1-itemSelected .col-lg-6:last-child');
        }
    }
    else if (firstColLength > lastColLength){
        $(this).clone().find('.addButton').remove().addClass('itemSelected').end().append(removeBtn).appendTo('.persona1-itemSelected .col-lg-6:last-child');
    }
    else{
        $(this).clone().find('.addButton').remove().addClass('itemSelected').end().append(removeBtn).appendTo('.persona1-itemSelected .col-lg-6:first-child');
    }
    //remove element
    $('.removeElement').on('click' , function() {
        $(this).parent().remove();
    })
    evenCheckPop++;
})

// Most Popular for second page
$('.persona2-most-popular li').on('click',function(e){
    e.preventDefault();

    $(this).find('span').html('<i class="fas fa-check"></i>').end()
        .appendTo('.persona2-itemSelected ul');

    $('.selected').on('click' , function() {
        $(this).remove();
    })

});


// Most Popular for third page
$('.persona3-most-popular li').on('click',function(e){
    e.preventDefault();
    $(this).find('span').html('<i class="fas fa-check"></i>').end().appendTo('.persona3-itemSelected ul');
});

// Most Popular for four page
$('.persona4-most-popular li').on('click',function(e){
    e.preventDefault();
    $(this).find('span').html('<i class="fas fa-check"></i>').end().appendTo('.persona4-itemSelected ul');
});


// for auto resize text if its overflow
var autoSizeText;
autoSizeText = function() {
  var el, elements, _i, _len, _results;
  elements = $('.afterSearch-sec-2 li h3');
  if (elements.length < 0) {
    return;
  }
  _results = [];
  for (_i = 0, _len = elements.length; _i < _len; _i++) {
    el = elements[_i];
    _results.push((function(el) {
      var resizeText, _results1;
      resizeText = function() {
        var elNewFontSize;
        elNewFontSize = (parseInt($(el).css('font-size').slice(0, -2)) - 1) + 'px';
        return $(el).css('font-size', elNewFontSize);
      };
      _results1 = [];
      while (el.scrollHeight > el.offsetHeight) {
        _results1.push(resizeText());
      }
      return _results1;
    })(el));
  }
  return _results;
};

$("#persona2Input" ).keyup(function(){
    autoSizeText();
});

$("#persona3Input" ).keyup(function(){
    autoSizeText();
});

$("#persona4Input" ).keyup(function(){
    autoSizeText();
});

// target element that we will observe
const target = document.body;

// config object
const config = {
  attributes: true,
  attributeOldValue: true,
  characterData: true,
  characterDataOldValue: true,
  childList: true,
  subtree: true
};

// subscriber function
function subscriber(mutations) {
  mutations.forEach((mutation) => {
    // handle mutations here
    removeRepeatedItem('.persona1-itemSelected .itemSelected','#persona1Inputautocomplete-list');
    removeRepeatedItem('#persona2-itemSelected .selected','#persona2Inputautocomplete-list');
    removeRepeatedItem('#persona3-itemSelected .selected','#persona3Inputautocomplete-list');
    removeRepeatedItem('#persona4-itemSelected .selected','#persona4Inputautocomplete-list');
  });
}

// instantiating observer
const observer = new MutationObserver(subscriber);

// observing target
observer.observe(target, config);


function removeRepeatedItem (selectedItems , autoCompleteItems ) {
     selectedItemArary = $(selectedItems + ' input').map(function() {
        return this.value;
    }).get();
    var autoCompleteItem = $(autoCompleteItems + ' li input').map(function() {
        return this.value;
    }).get();
    $.each(autoCompleteItem, function(idx, value) {
        if ($.inArray(value, selectedItemArary) !== -1) {
            $(autoCompleteItems + ' input' + '[value="'+ value + '"]').parent().hide();
        }
    });
}






    